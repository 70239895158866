<template>
    <div>
        <div class="top">
            <div class="tab_bar">
                <div :class="['choose',tab===0?'active':'']" @click="switch_tab('待处理')">待处理</div>
                <div :class="['choose',tab===1?'active':'']" @click="switch_tab('预约成功')">预约成功</div>
                <div :class="['choose',tab===2?'active':'']" @click="switch_tab('核销成功')">核销成功</div>
                <div :class="['choose',tab===-3?'active':'']" @click="switch_tab('预约失败')">预约失败</div>
            </div>
            <div class="selector-wrapper">
                <div class="selector-label-wrapper">
                    <div class="selector-label" :class="currentLabel==0 ? 'selector-active' : ''" @click="clickLabel(0,0)">今天</div>
                    <div class="selector-label" :class="currentLabel==-6 ? 'selector-active' : ''" @click="clickLabel(-6,0)">近七天</div>
                    <div class="selector-label" :class="currentLabel=='all' ? 'selector-active' : ''" @click="clickLabel('all',0)">全部</div>
                </div>
                <div class="selector-date-wrapper">
                    <input class="selector-date-input" type="text" v-model="startTime" @click="show = true"/>
                    <div class="selector-font">至</div>
                    <input class="selector-date-input" type="text" v-model="endTime" @click="show = true"/>
                </div>
            </div>
            <van-calendar v-model="show" :min-date="minDate" :default-date="computedDefaultDate" type="range" @confirm="onConfirm" />
        </div>
        <div style="width: 100%;height: 6rem;"></div>
        <van-list
                v-model="listLoading"
                :finished="listLoadingFinished"
                finished-text="没有更多了"
                @load="onLoad"
        >
            <div class="item" v-for="(item,index) in listLoadingList" :key="index" @click="toRe(item.id)">
                <p>姓名：{{item.name}}</p>
                <p v-if="item.order_id" style="color:#ca1416">订单号：{{item.orderID}}</p>
                <p v-if="item.order_id" style="color:#ca1416">订单名：{{item.gooods_name}}</p>
                <p>性别：{{item.sex}}</p>
                <p>预约手机：{{item.phone}}</p>
                <p>预约人数：{{item.number}}</p>
                <p>预约日期：{{item.date}}</p>
                <p>预约时间段：{{item.time}}</p>
                <p>状态：{{item.statusText}}</p>
                <p>备注：{{item.remark}}</p>
            </div>
        </van-list>
        <check-float-nav></check-float-nav>
    </div>
</template>
<script>
  import CheckFloatNav from '../../components/check/CheckFloatNav'
  import { getStandardDate, formatGMT2StandardDate } from '../../utils/tools'
  import { globalConfig, listLoading } from '../../utils/mixin'
  export default {
    name: 'OrderList',
    mixins: [globalConfig, listLoading],
    components: { CheckFloatNav },
    data () {
      return {
        tab: 2,
        page: 1,
        permission: '',
        list: [],
        loading: false,
          minDate: new Date(2010, 0, 1),
          startTime: '',
          endTime: '',
          show: false,
          currentLabel: '-6'
      }
    },
    methods: {
        // 日历选择回调函数
        onConfirm (date) {
            const [start, end] = date
            this.startTime = formatGMT2StandardDate(start)
            this.endTime = formatGMT2StandardDate(end)
            this.currentLabel = 99
            this.choiceTime()
            this.show = false
            this.loadingInit()
        },
        // 点击快捷搜索标签
        clickLabel (start, end) {
            if (start == 'all') {
                this.startTime = '2017-01-01'
            } else {
                this.startTime = getStandardDate(start)
            }
            this.currentLabel = start
            this.endTime = getStandardDate(end)
            this.choiceTime()
            this.loadingInit()
        },
        // 选择时间
        choiceTime () {
            this.$emit('refreshTime', this.startTime, this.endTime)
        },
        // 字符串转成Date对象
        str2Date (str) {
            const arr = str.split('-')
            return new Date(arr[0], arr[1] - 1, arr[2])
        },
      switch_tab: function (status) {
        switch (status) {
            case '待处理':
                this.tab = 0
                break
            case '预约成功':
                this.tab = 1
                break
            case '核销成功':
                this.tab = 2
                break
            case '预约失败':
                this.tab = -3
                break
          default:
            break
        }
        this.loadingInit()
      },
      init: function () {
            const that = this
        this.axios.get(this.apiUrl + 'checkh5/api/init').then((response) => {
          console.log(response)
          const data = response.data
          if (data.status === 0) {
              this.$router.replace('/check/login')
          } else {
              this.permission = data.permission
              this.startTime = getStandardDate(-6)
              this.endTime = getStandardDate(0)
              this.choiceTime() // 初始化，冒泡到主组件
              console.log(this.computedDefaultDate)
              setTimeout(function () {
                  that.switch_tab('待处理')
              }, 50)
          }
        })
      },
        loadingInit: function () {
            //  初始化
            this.page = 1
            this.list = []
            console.log(this.startTime)
            console.log(this.endTime)
            this.listLoadingInit(this.apiUrl + 'checkh5/order/getReserveList', 'list', 1, {
                status: this.tab,
                startTime: this.startTime,
                endTime: this.endTime
            })
        },
        toRe: function (id) {
            console.log(id)
            // this.$router.push('/check/checkReserve/' + id)
        }
    },
    created () {
      this.init()
    },
      computed: {
          computedDefaultDate: function () {
              return [this.str2Date(this.startTime), this.str2Date(this.endTime)]
          }
      },
      mounted () {

      }
  }
</script>
<style scoped>
    .top{
        position: fixed;
        top: 0;
        width: 100%;
    }
    .tab_bar{
        height: 2.6rem;
        line-height: 2.6rem;
        display: flex;
        align-items: center;
        justify-content: space-around;
        background-color: #ffffff;
        padding: 0 0.5rem;
    }
    .tab_bar .choose{
        font-size: 1rem;
        padding:0.1rem 0.3rem;
        color: #7e7e7e;
    }
    .tab_bar .active{
        border-bottom: 1px solid #ca1416;
        color: #ca1416;
    }
    .selector-wrapper{
        width: 100vw;
        display: flex;
        background-color: #FFFFff;
        box-shadow:0 8px 10px -8px #999;
        padding: 0.5rem 0;
    }
    .selector-label-wrapper{
        width: 45%;
        display: flex;
        color: #999;
        font-size: 3vw;
        padding: 3vw 1vw;
        box-sizing: border-box;
    }
    .selector-label{
        background: #f8f8f8;
        padding: 0.25vw 2vw;
        border-radius: 4vw;
        margin: 0 1.2vw;
    }
    .selector-active{
        background: #FFE8D8;
        padding: 0.2vw 2vw;
        border-radius: 4vw;
        color: #ff4949;
        margin: 0 1.2vw;
    }
    .selector-date-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 0 1rem 0 0;
        width: 55%;
        box-sizing: border-box;
    }
    .selector-date-input{
        width: 20vw;
        height: 5vw;
        border-radius: 5vw;
        border: 1px solid #b8b8b8;
        font-size: 2.8vw;
        text-align: center;
    }
    .selector-font{
        margin: 0 1vw;
        font-size: 3.2vw;
        color: #333;
    }
    .item{
        width: 92%;
        margin: 1rem auto 0;
        background-color: white;
        box-shadow:0 0 5px #999999;
        border-radius: 2px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding:0.3rem 0.6rem;
    }
    .item p{
        font-size: 1rem;
        color: #808080;
        margin-top: 0.1rem;
    }
</style>
